import './CustomerAgreement.css';

import { SendSharp } from '@material-ui/icons';
import React, { Fragment, useEffect, useState } from 'react';

import { PaymentGraphqlClient } from '../../../../backend-client/paymentGraphqlBackend';
import Spinner from '../../../../components/Spinner';
import { useGetBrokerInfoByQuoteIdLazyQuery } from '../../../../generated/graphql';
import getEnv from '../../../../utils/getEnv';

const { REACT_APP_ADMIN_FRONTEND_URL } = getEnv();

const DEFAULT_EMAIL_SUBJECT =
  'ACTION REQUIRED: Please sign application for purchase of Foxquilt business insurance';

interface CustomerAgreementProps {
  customerName: string;
  email: string;
  quoteId: string;
  paymentGraphqlClient: PaymentGraphqlClient | null;
}

const CustomerAgreement: React.FC<CustomerAgreementProps> = ({
  quoteId,
  customerName,
  email,
  paymentGraphqlClient
}) => {
  const navigateToFoxden = () => {
    window.location.href = `${REACT_APP_ADMIN_FRONTEND_URL}/broker-portal/dashboard`;
  };

  const navigateToPayment = (paymentUrl: string) => {
    window.location.href = paymentUrl;
  };

  const sendForESignatureOnClick = () => {
    if (quoteId && !isSendTnCESignatureLoading && paymentGraphqlClient) {
      setIsSendTnCESignatureLoading(true);
      paymentGraphqlClient
        .sendTnCESignature(quoteId, subject, message)
        .then((data) => {
          const res = data.valueOf();
          setSendTnCESignatureSucceed(res);
          setSendTnCESignatureError(undefined);
        })
        .catch((err) => {
          setSendTnCESignatureSucceed(false);
          setSendTnCESignatureError(err);
        })
        .finally(() => {
          setIsSendTnCESignatureLoading(false);
        });
    }
  };

  const proceedToPaymentOnClick = () => {
    if (quoteId && !isProceedToPaymentLoading && paymentGraphqlClient) {
      setIsProceedToPaymentLoading(true);
      paymentGraphqlClient
        .proceedToPayment(quoteId)
        .then((data) => {
          const res = data.valueOf();
          setProceedToPaymentError(undefined);
          setIsProceedToPaymentLoading(false);
          navigateToPayment(res);
        })
        .catch((err) => {
          setProceedToPaymentError(err);
          setIsProceedToPaymentLoading(false);
        });
    }
  };

  const [
    checkCommunicationAgreement,
    setCheckCommunicationAgreement
  ] = useState(false);
  const [checkBindingAgreement, setCheckBindingAgreement] = useState(false);

  const [
    getBrokerInfo,
    { data: getBrokerInfoDataResult, loading: getBrokerInfoLoading }
  ] = useGetBrokerInfoByQuoteIdLazyQuery({
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    getBrokerInfo({
      variables: { quoteId }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateDefaultEmailMessageBody = (): string => {
    // Gets customerFirstName from the first word in provided customerName, using 'there' if name does not exist
    const customerFirstName = customerName
      ? customerName.split(' ')[0]
      : 'there';
    return `Hi ${customerFirstName},\nPlease e-sign the application for your Foxquilt business insurance policy. \
The policy will not be issued and the insurance coverage will NOT be in effect until the application is signed.
Thank you for choosing Foxquilt!`;
  };

  const resetSubjectInput = () => {
    setSubject(DEFAULT_EMAIL_SUBJECT);
  };

  const resetMessageInput = () => {
    setMessage(generateDefaultEmailMessageBody());
  };

  const isLoading = getBrokerInfoLoading || !getBrokerInfoDataResult;
  const [subject, setSubject] = useState(DEFAULT_EMAIL_SUBJECT);
  const [message, setMessage] = useState(generateDefaultEmailMessageBody());
  const [isSendTnCESignatureLoading, setIsSendTnCESignatureLoading] = useState(
    false
  );
  const [sendTnCESignatureSucceed, setSendTnCESignatureSucceed] = useState(
    false
  );
  const [sendTnCESignatureError, setSendTnCESignatureError] = useState<
    string | undefined
  >(undefined);
  // proceedToPayment API
  const [isProceedToPaymentLoading, setIsProceedToPaymentLoading] = useState(
    false
  );
  const [proceedToPaymentError, setProceedToPaymentError] = useState<
    string | undefined
  >(undefined);

  if (sendTnCESignatureError || proceedToPaymentError) {
    throw Error('Error sending TnC e-signature');
  }

  return (
    <div className="customer-agreement-container">
      <div className="term-container">
        <h2 className="term-title">Electronic Communication Agreement</h2>
        <p className="term-description">
          My customer agrees to accept delivery of the insurance policy and
          related documents via email to address provided to Foxquilt.
        </p>
        <div className="term-check">
          <input
            data-testid="communication-agreement-checkbox"
            type="checkbox"
            onClick={() => {
              setCheckCommunicationAgreement(!checkCommunicationAgreement);
            }}
          />
          <label>The customer understands and agrees.</label>
        </div>
      </div>
      <div className="term-container">
        <h2 className="term-title">Binding Agreement</h2>
        <p className="term-description">
          My customer has read the policy information and confirmed it is
          correct. By checking this box and providing the premium payment, my
          customer agrees that they are entering into a binding agreement with
          the Insurer.
        </p>
        <div className="term-check">
          <input
            data-testid="binding-agreement-checkbox"
            type="checkbox"
            onClick={() => {
              setCheckBindingAgreement(!checkBindingAgreement);
            }}
          />
          <label>The customer understands and agrees.</label>
        </div>
      </div>
      {sendTnCESignatureSucceed ? (
        <Fragment>
          <div className="term-container">
            <h2 className="term-title text-secondary">
              Terms & Conditions Sent!
            </h2>
            <p className="term-description">
              You can continue to purchase and select your client&apos;s payment
              preferences while waiting for their e-signature, or you return to
              the Foxden dashboard.
              <hr />
              We will notify you by email when your client completes the
              e-signature.
            </p>
          </div>
          <div className="flex flex-col sm:flex-row gap-y-4">
            <button
              className="proceed-payment sm:px-6 sm:py-4 py-2 rounded-md text-white uppercase sm:text-2xl text-lg font-bold sm:mr-4"
              onClick={proceedToPaymentOnClick}
            >
              {isProceedToPaymentLoading // TODO: The loading UI is TBD by Josh
                ? 'Waiting to proceed'
                : 'Proceed to payment'}
            </button>
            <button
              className="return-foxden sm:px-6 sm:py-4 py-2 rounded-md uppercase sm:text-2xl text-lg font-bold"
              onClick={navigateToFoxden}
            >
              Return to Foxden
            </button>
          </div>
        </Fragment>
      ) : (
        <div className="term-container">
          <h2 className="term-title">Terms & Conditions</h2>
          <p className="term-description">
            Foxquilt uses Docusign to provide a safe and secure method for your
            customer to electronically sign their documents. At no extra cost,
            your customer will receive a copy of their documents requiring
            signtures and they will be able to sign them online and submit them
            back to Foxquilt. You will be notified by email as soon as your
            insured has signed their documents.
          </p>
          <div className="customer-info flex flex-col space-y-3 mt-4 sm:text-base text-sm">
            <h2 className="sm:text-2xl text-xl text-gray-500">
              <b>Customer&apos;s Info</b>
            </h2>
            <hr className="border-solid border-gray-500 mt-2 mb-2" />
            <span>
              <b className="mr-2">Customer&apos;s Name:</b>
              {customerName}
            </span>
            <span>
              <b className="mr-2">Customer&apos;s Email Address:</b>
              {email}
            </span>
          </div>
          <div className="agent-info mt-6 flex flex-col space-y-3 sm:text-base text-sm">
            <h2 className="sm:text-2xl text-xl text-gray-500">
              <b>Agent/Broker Info</b>
            </h2>
            <hr className="border-solid border-gray-500 mt-2 mb-2" />
            {isLoading ? (
              <Spinner />
            ) : (
              <div className="grid grid-cols-3 gap-4">
                <b className="col-span-1 mt-4 mr-2">Agent/Broker Name:</b>
                <input
                  className="col-span-2 border-solid border-gray-500 border-lg"
                  value={
                    getBrokerInfoDataResult?.getBrokerInfoByQuoteId.name || ''
                  }
                  type="text"
                  readOnly={true}
                />
                <b className="col-span-1 mt-4 mr-2">
                  Agent/Broker Email Address:
                </b>
                <input
                  className="col-span-2 border-solid border-gray-500 border-lg"
                  value={
                    getBrokerInfoDataResult?.getBrokerInfoByQuoteId.email || ''
                  }
                  type="text"
                  readOnly={true}
                />
                <b className="col-span-1 mt-4 mr-2">Subject:</b>
                <input
                  className="col-span-2 border-solid border-gray-500 border-lg"
                  value={subject}
                  type="text"
                  onChange={(e) => setSubject(e.target.value)}
                />
                <div className="col-span-1 sm:block hidden" />
                <p className="col-span-2 text-faded">
                  Subject will also be the e-signature envelope name
                </p>
                {subject === DEFAULT_EMAIL_SUBJECT && (
                  <div className="col-span-1 sm:block hidden" />
                )}
                {subject === '' ? (
                  <>
                    <div className="col-span-1 sm:block hidden" />
                    <p className="col-span-1 text-alert">
                      Subject field cannot be blank.
                    </p>
                  </>
                ) : (
                  <div className="col-span-1 sm:block hidden" />
                )}
                {subject !== DEFAULT_EMAIL_SUBJECT ? (
                  <button
                    className={`col-span-${
                      subject === '' ? '1' : '2'
                    } reset-input`}
                    onClick={resetSubjectInput}
                  >
                    {'Reset to default text.'}
                  </button>
                ) : (
                  <div className="col-span-1 sm:block hidden" />
                )}
                <b className="col-span-1 mt-4 mr-2">Message to Client:</b>
                <textarea
                  className="col-span-2 border-solid border-gray-500 border-lg"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                {message === generateDefaultEmailMessageBody() && (
                  <div className="col-span-1 sm:block hidden" />
                )}
                {message === '' ? (
                  <>
                    <div className="col-span-1 sm:block hidden" />
                    <p className="col-span-1 text-alert">
                      Message field cannot be blank.
                    </p>
                  </>
                ) : (
                  <div className="col-span-1 sm:block hidden" />
                )}
                {message !== generateDefaultEmailMessageBody() ? (
                  <button
                    className={`col-span-${
                      message === '' ? '1' : '2'
                    } reset-input`}
                    onClick={resetMessageInput}
                  >
                    {'Reset to default text.'}
                  </button>
                ) : (
                  <div className="col-span-1 sm:block hidden" />
                )}
                <div className="col-span-1 sm:block hidden" />
                <div className="sm:col-span-1 col-span-2">
                  <button
                    className="send-signature text-left px-6 py-4 rounded-md text-white"
                    disabled={
                      !checkCommunicationAgreement ||
                      !checkBindingAgreement ||
                      subject === '' ||
                      message === ''
                    }
                    onClick={sendForESignatureOnClick}
                  >
                    <SendSharp className="mr-2" />
                    {isSendTnCESignatureLoading
                      ? 'Sending...'
                      : 'Send for e-Signature'}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerAgreement;
