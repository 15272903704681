import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import foxquiltBrand from '../../images/brand.png';
import foxquiltLogo from '../../images/logo.png';
import { Context, ContextValue } from '../../utils/store';
import ModalWithHeader from '../ModalWithHeader';
import ProgressBar from '../Navigation/ProgressBar';
import HelpText from './HelpText';

const Header: React.FC = () => {
  const context: ContextValue | null = useContext(Context);

  const headerTitle = context ? context.headerTitle : '';
  const progress = context ? context.progress : 0;
  const isCustomerAgreementActive = context
    ? context.isCustomerAgreementActive
    : false;
  const [helperShowing, setHelperShowing] = useState(false);

  return (
    <div className="sticky z-10 w-full top-0 p-0 m-0" data-testid="Header">
      <div className="shadow-lg bg-white" data-testid="Header">
        <div className="w-full md:h-20 h-14 md:px-0 px-6 md:py-0 py-2">
          <nav className="flex justify-between items-center w-full h-full">
            <div className="flex md:pl-20 md:h-16">
              <Link to="/">
                <div className="flex w-full h-full">
                  <div className="md:pt-1 lg:w-11 md:w-9">
                    <img
                      className="md:w-full"
                      src={foxquiltLogo}
                      alt="company-logo"
                    />
                  </div>
                  <div className="ml-2 lg:w-24 w-20 flex items-center">
                    <img
                      className="w-full"
                      src={foxquiltBrand}
                      alt="company-logo"
                    />
                  </div>
                </div>
              </Link>
            </div>
            {headerTitle ? (
              <div className="text-3xl hidden md:block pr-24">
                <span className="text-navy-dark font-bold">
                  {isCustomerAgreementActive
                    ? 'Clients Agreements'
                    : 'Tailored Quote For'}
                  :{' '}
                </span>
                <span className="font-medium ml-1">{headerTitle}</span>
              </div>
            ) : null}
            <div id="helpMenu">
              <div className="h-20 flex md:block items-center md:pr-20">
                <div
                  onMouseLeave={() => setHelperShowing(false)}
                  onMouseEnter={() => setHelperShowing(true)}
                  className="hidden md:flex absolute w-80 h-full top-0 right-0 items-center justify-end whitespace-nowrap tracking-wider font-medium text-base text-xs text-secondary underline pr-20"
                >
                  Need help?
                </div>
                <a
                  onClick={() => setHelperShowing(!helperShowing)}
                  className="block md:hidden text-secondary font-medium text-base underline"
                >
                  Need help?
                </a>
              </div>
              {helperShowing ? (
                <>
                  <div
                    onMouseEnter={() => setHelperShowing(true)}
                    onMouseLeave={() => setHelperShowing(false)}
                    className="md:block hidden absolute w-80 top-20 right-0 min-w-fit rounded-bl-xl text-base text-xs"
                  >
                    <div className="p-4 bg-gray-300 rounded-bl-xl shadow-md">
                      <HelpText></HelpText>
                    </div>
                  </div>
                  <ModalWithHeader
                    headerText="Need help?"
                    onBackgroundClick={() => setHelperShowing(false)}
                    additionalRootStyleClasses="md:hidden"
                  >
                    <HelpText></HelpText>
                  </ModalWithHeader>
                </>
              ) : null}
            </div>
          </nav>
        </div>
      </div>
      <ProgressBar percentage={progress} />
    </div>
  );
};

export default Header;
