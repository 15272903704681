import { Loop } from '@material-ui/icons';
import React from 'react';

const Spinner: React.FC = () => (
  <div
    data-testid="LoopIcon"
    className="mx-auto animate-spin h-12 w-12 text-gray-400"
  >
    <Loop className="h-full w-full text-gray inline" />
  </div>
);

export default Spinner;
